<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>
<!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-message" class="ml-5" v-model="email"></el-input>-->
<!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-position" class="ml-5" v-model="address"></el-input>-->
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>
      <!-- <el-upload action="http://39.106.92.106:9090/line/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button> -->
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column label="所属部门">
        <template v-slot="scope">
          <span>{{ parts.find(v => v.id === scope.row.partId) ? parts.find(v => v.id === scope.row.partId).name : ''  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="负责小组">
        <template v-slot="scope">
          <span>{{ getGroupNames(scope.row.id)  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="负责人">
        <template v-slot="scope">
          <span>{{ getUserNames(scope.row.id)  }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作"  width="180" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="handleEdit(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>
          <el-popconfirm
              class="ml-5"
              confirm-button-text='确定'
              cancel-button-text='我再想想'
              icon="el-icon-info"
              icon-color="red"
              title="您确定删除吗？"
              @confirm="del(scope.row.id)"
          >
            <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="名称">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所属部门">
          <el-select clearable v-model="form.partId" placeholder="请选择部门" style="width: 100%">
            <el-option v-for="item in parts" :key="item.name" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="负责小组">
          <el-button type="primary" plain @click="handleLineGroup(form.id)">编辑负责小组 <i class="el-icon-document"></i></el-button>
        </el-form-item>
        <el-form-item label="负责人">
          <el-button type="primary" plain @click="handleLineUser(form.id)">编辑负责人 <i class="el-icon-document"></i></el-button>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑负责小组" :visible.sync="dialogFormVisible3" width="35%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="车间ID">
          <el-input v-model="form2.lineId" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="负责小组">
          <el-select v-model="form2.selectedGroups" multiple filterable placeholder="请选择">
            <el-option
                v-for="item in groups"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="saveLineGroup">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑负责人" :visible.sync="dialogFormVisible4" width="35%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="车间ID">
          <el-input v-model="form3.lineId" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="负责人">
          <el-select v-model="form3.selectedUsers" multiple filterable placeholder="请选择">
            <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible4 = false">取 消</el-button>
        <el-button type="primary" @click="saveLineUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Line",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      name: "",
      form: {},
      dialogFormVisible: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
      multipleSelection: [],
      parts: [],
      users: [],
      groups: [],
      lineGroups: [],
      lineUsers: [],
      form3: {
        lineId: '',
        selectedUsers: []
      },
      form2: {
        lineId: '',
        selectedGroups: []
      },
      baseURLdata: request.defaults.baseURL,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/line/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
      this.request.get("/partment").then(res => {
        this.parts = res.data
      })
      this.request.get("/user").then(res => {
        this.users = res.data
      })
      this.request.get("/mtgroup").then(res => {
        this.groups = res.data
      })
      this.request.get("/lineGroup").then(res => {
        this.lineGroups = res.data
      })
      this.request.get("/lineUser").then(res => {
        this.lineUsers = res.data
      })
    },
    save() {
      this.request.post("/line", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
      this.$nextTick(() => {
        if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
      })
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
       this.$nextTick(() => {
         if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
       })
    },
    del(id) {
      this.request.delete("/line/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择需要删除的数据")
        return
      }
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/line/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.name = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleLineGroup(lineId){
      this.form2 = {
        selectedGroups: this.getGroupIds(lineId) || [],
        lineId: lineId,
      }
      this.dialogFormVisible3 = true;
    },
    saveLineGroup(){
      this.request.post("/lineGroup/handle", this.form2).then(res => {
        if (res.code === '200') {
          this.$message.success("修改成功")
          this.dialogFormVisible3 = false
          this.load()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getGroupIds(lineId) {
      const lineGroup = this.lineGroups.filter(item => item.lineId === lineId);
      console.log(lineGroup.map(item => item.groupId))
      return lineGroup.map(item => item.groupId);
    },
    getGroupNames(lineId) {
      const lineGroup = this.lineGroups.filter(item => item.lineId === lineId);
      return lineGroup.map(item => {
        const group = this.groups.find(u => u.id === item.groupId);
        return group ? group.name : '';
      }).join(', ');
    },
    handleLineUser(lineId){
      this.form3 = {
        selectedUsers: this.getUserIds(lineId) || [],
        lineId: lineId,
      }
      this.dialogFormVisible4 = true;
    },
    saveLineUser(){
      this.request.post("/lineUser/handle", this.form3).then(res => {
        if (res.code === '200') {
          this.$message.success("修改成功")
          this.dialogFormVisible4 = false
          this.load()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getUserIds(lineId) {
      const lineUser = this.lineUsers.filter(item => item.lineId === lineId);
      console.log(lineUser.map(item => item.userId))
      return lineUser.map(item => item.userId);
    },
    getUserNames(lineId) {
      const lineUser = this.lineUsers.filter(item => item.lineId === lineId);
      return lineUser.map(item => {
        const user = this.users.find(u => u.id === item.userId);
        return user ? user.nickname : '';
      }).join(', ');
    },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open(this.baseURLdata+"/line/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
